<template>
	<div class="calendar_horse_content">
		<div class="d-flex d-md-block d-lg-flex justify-content-between">
			<div>
				<div class="d-md-none d-inline">
					{{ schedule.schedule_start }}h {{ schedule.schedule_end }}h
				</div>
				<template v-if="params.schedulehorse_mare.horse_nom">
					<router-link :to="{ name: 'horseFiche', params: { horse_id: params.schedulehorse_mare.horse_id }}">
						<b>{{ params.schedulehorse_mare.horse_nom }}</b>
					</router-link>
					<br>
					<small>{{ params.schedulehorse_mare.horse_transpondeur }}</small>
					<br>
					<template v-if="sms">
						{{ sms.seasonmarestallion_status }}
					</template>
					<template v-else-if="mare_status">
						{{ $t('gynecologie.'+mare_status.marestatus_code) }}
					</template>
					<br>
				</template>
				<template v-if="params.schedulehorse_contact.contact_id">
					{{ params.schedulehorse_contact.contact_firstname }} {{ params.schedulehorse_contact.contact_lastname }}
					<br>
					<template v-if="params.schedulehorse_contact.phones.length > 0">
						{{ formatPhone(params.schedulehorse_contact.phones[0].phone_combine) }}
					</template>
				</template>
				<template v-else-if="params.schedulehorse_tiers.tiers_id">
					{{ params.schedulehorse_tiers.tiers_rs }}
					<br>
					<template v-if="params.schedulehorse_tiers.phones.length > 0">
						{{ formatPhone(params.schedulehorse_tiers.phones[0].phone_combine) }}
					</template>
				</template>
			</div>
			<div>
				<b-button pill variant="primary py-1 px-2 calendar_btn_edit" @click="edit">
					<font-awesome-icon :icon="['fal', 'marker']"></font-awesome-icon><span class="d-none d-md-inline d-lg-none"> {{ $t('global.editer') }}</span>
				</b-button>
				<b-button pill variant="secondary py-1 px-2 calendar_btn_edit" @click="deleteSchedule">
					<font-awesome-icon :icon="['fal', 'trash-alt']"></font-awesome-icon><span class="d-none d-md-inline d-lg-none"> {{ $t('global.supprimer') }}</span>
				</b-button>
			</div>
		</div>
		<div>
			{{ commentaire_formatted }}
		</div>
		<div v-if="params.schedulehorse_mare.horse_nom" class="d-flex justify-content-between">
			<b-button v-if="!contrat.avenant_contract" :class="class_contract" size="sm" variant="secondary p-1 mr-1 mx-lg-0" @click="createContract">
				<font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon><span class="d-md-none d-lg-inline"> {{ $t('monte.contrat_a_faire') }}</span>
			</b-button>
			<b-button v-else-if="contrat.avenant_status == 3" class="green" size="sm" variant="secondary p-1 mx-1 mx-lg-0"  @click="openContract">
				<font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
				<span class="d-md-none d-lg-inline"> {{ $t('monte.contrat_signe') }} </span>
				<span :class="class_contract"> €</span>
			</b-button>
			<b-button v-else class="red" size="sm" variant="secondary p-1 mx-1 mx-lg-0" @click="openContract">
				<font-awesome-icon :icon="['fal', 'edit']"></font-awesome-icon>
				<span class="d-md-none d-lg-inline"> {{ $t('monte.contrat_a_signe') }} </span>
				<span :class="class_contract"> €</span>
			</b-button>

			<b-button :class="season_mare && season_mare.seasonmare_sanitary ? 'green' : 'red'" size="sm" variant="secondary p-1 mx-1 mx-lg-0" @click="openActe"> <!-- TODO -->
				<font-awesome-icon :icon="['fal', 'syringe']"></font-awesome-icon><span class="d-md-none d-lg-inline"> {{ $t('monte.sanitaire') }}</span>
			</b-button>
			<b-button size="sm" :class="saillie.length > 0 ? 'green' : ''" variant="secondary p-1 mx-1 mx-lg-0" @click="openAddActe">
				<font-awesome-icon :icon="['fal', 'venus-mars']"></font-awesome-icon><span class="d-md-none d-lg-inline"> {{ $t('monte.saillie') }}</span>
			</b-button>
		</div>
	</div>
</template>

<style scoped>
	.btn.red {
		border-color: #F21207;
		color: #F21207;
	}
	.btn.green {
		border-color: #569E18;
		color: #569E18;
	}
</style>

<script type="text/javascript">
	import Vue from 'vue'
	import Contract from '@/mixins/Contract'
	import Tiers from '@/mixins/Tiers'
	import Gynecologie from '@/mixins/Gynecologie'
	import Shutter from '@/mixins/Shutter'
	import MonteShutters from '@/mixins/shutters-manager/Monte'
	import { parsePhoneNumberFromString as parseMax, AsYouType } from 'libphonenumber-js/max'

	export default {
		name: 'CellCalendarMain',
		mixins: [Contract, Tiers, Gynecologie, Shutter, MonteShutters],
		props: ['schedule', 'params', 'edit_schedule', 'season_id', 'delete_schedule', 'previous_season'],
		data () {
			return {
				contrat: {},
				mare_status: null,
				season_mare: null,
				class_contract: '',
				sms: null,
				saillie: []
			}
		},

		mounted() {
			this.init_component()
		},

		methods: {
			async init_component() {
				if(this.params.schedulehorse_mare.horse_id) {
					const contrats = await this.loadContracts(this.params.schedulehorse_mare.horse_id)

					const avenant = contrats.filter(avenant => {
						if(avenant.contract.length === 0) return false
						if(avenant.contract.config.length === 0) return false
						if(!avenant.contract.config.horse) return false
						if(!avenant.contract.contract_contractconfig) return false
						return avenant.contract.config.horse.horse_id == this.params.schedulehorse_stallion.horse_id
						&& avenant.contract.config.season.season_id == this.season_id
						&& avenant.avenant_id == avenant.contract.avenant.avenant_id
					})

					if(avenant.length == 0) {
						this.contrat = {avenant_status: 0}
					} else {
						this.contrat = avenant[0];
						if(this.contrat.contract.contract_tiers.length > 0 && window.navigator.onLine) {
							const tiers_id = this.contrat.contract.contract_tiers[0].tiers.tiers_id
							const factures = await this.getFactureSoldeTiers(tiers_id)
							this.class_contract = factures ? 'd-none' : 'text-danger'
						}
					}

					const season_mare = await this.loadSeasonMareStallionBySeasonHorse(this.params.schedulehorse_mare.horse_id, this.season_id)
					if(season_mare.length > 0) {
						this.season_mare = season_mare[0]
					}
					const previous = await this.loadSeasonMareStallionBySeasonHorse(this.params.schedulehorse_mare.horse_id, this.previous_season)
					if(previous.length > 0) {
						this.sms = await this.getSeasonMareStallionDefaultBySeasonMare(previous[0].seasonmare_id)
					}
					if(!this.sms && this.season_mare) {
						const status = await this.loadMareStatus()
						this.mare_status = status.find(stat => stat.marestatus_id == this.season_mare.seasonmare_status);
					}

					this.saillie = await this.getSaillieByMareBetweenDate(this.params.schedulehorse_mare.horse_id, this.params.schedulehorse_date, this.params.schedulehorse_date)
				}
			},

			edit() {
				this.$emit('update:edit_schedule', {schedule_info: this.params, schedule_id: this.schedule.schedule_id, horse_id: this.params.schedulehorse_stallion.horse_id, season_mare: this.season_mare})
			},

			openActe() {
				if(this.shutterPanel().isOpen('acte-jument')) {
					this.shutterPanel().close('acte-jument')
					return false
				}

                this.shutterPanel().keepGiven('monte-main-calendar')
				const shutter = this.monteShutters['acte-jument']
				shutter.props.horse_id = this.params.schedulehorse_mare.horse_id
				shutter.props.horse_nom = this.params.schedulehorse_mare.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('acte-jument')
				}

				this.shutterPanel().open(shutter)
			},

			openAddActe() {
				if(this.shutterPanel().isOpen('ajout-acte')) {
					this.shutterPanel().close('ajout-acte')
					return false
				}

				this.shutterPanel().keepGiven('monte-main-calendar')
				const shutter = this.monteShutters['ajout-acte']
				shutter.props.horses_ids = [this.params.schedulehorse_mare.horse_id]
				shutter.props.actes_ids = []
				shutter.props.group_preselect = "REPRO"
				shutter.props.type_preselect = "REPRO_SAILLIE_EN_MAIN"
				shutter.props.date_preselect = this.params.schedulehorse_date
				shutter.props.preselect = this.params.schedulehorse_stallion.horse_nom

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-acte')
				}

				this.shutterPanel().open(shutter)
			},

			async createContract() {
				if(this.params.schedulehorse_mare.horse_id) {
					const tiers = await this.loadTiersHorse(this.params.schedulehorse_mare.horse_id)
					if(tiers.length == 0) {
						this.failureToast("monte.jument_no_tiers")
						this.showAjoutTiers()
						return false
					}

					this.contrat.contract = {contract_id: await this.addContractFromHorse(this.params.schedulehorse_mare.horse_id)}
				} else {
					this.contrat.contract = {contract_id: await this.addContractFromStallion()}
				}
				this.openContract()
			},

			showAjoutTiers() {
				this.shutterPanel().keepGiven('monte-main-calendar')
				const shutter = this.monteShutters['ajout-tiers-mare']
				shutter.props.horse = this.params.schedulehorse_mare

				shutter.onOk = () => {
					this.shutterPanel().close('ajout-tiers-mare')
					this.createContract()
				}

				this.shutterPanel().open(shutter)
			},

			openContract() {
				if(this.shutterPanel().isOpen('contrat')) {
	                this.shutterPanel().close('contract-a-valider')
	                this.shutterPanel().close('contract-bons-valider')
	                this.shutterPanel().close('contract-invoices')
					this.shutterPanel().close('contrat')
					return false
				}

				this.shutterPanel().keepGiven('monte-main-calendar')
				const shutter = this.monteShutters['contrat']
				shutter.props.contract_id = this.contrat.contract.contract_id
				shutter.props.stallion_id = this.params.schedulehorse_stallion.horse_id

				shutter.onOk = () => {
					this.shutterPanel().close('contrat')
					this.init_component()
				}

				this.shutterPanel().open(shutter)
			},

			deleteSchedule() {
				this.$emit('update:delete_schedule', {schedulehorse_id: this.params.schedulehorse_id})
			},

			formatPhone(number) {
				return new AsYouType(Vue.i18n.locale()).input(number)
			}
		},

		computed: {
			commentaire_formatted() {
				return this.params.schedulehorse_commentaire //.substr(0, 25) + '...'
			}
		}
	}

</script>